.infoWrap {
  height: calc(100vh - 170px);
  flex-grow: 1;
  display: flex;
  flex-flow: column;
}
.infoScrollWrap {
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  padding: 10px;
  border: 1px solid rgb(240, 240, 240);
}
.infoScroll {
  flex-grow: 1;
}

.infoWrapFooter {
  width: 100%;
  background: #ffffff;
  border-top: 1px solid rgb(240, 240, 240);
  margin-top: 10px;
}

.infoTitle {
  background: rgb(240, 240, 240);
  padding: 6px 12px;
  font-weight: bold;
}

.infoItem {
  padding: 4px 0;
  display: flex;
  align-items: center;

  &:nth-child(2n) {
    background: rgb(251, 251, 251);
  }
}

.infoLabel {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
  text-align: right;
  margin-right: 32px;
  color: #666;
}

.infoValue {
  flex-grow: 1;
  color: #666;
  padding: 0 16px 0 0;
}

.infoValueTable {
  :global {
    th.ant-table-cell {
      white-space: nowrap;
    }

    .ant-table tbody .ant-table-cell {
      padding: 4px 8px!important;
    }
  }
}

.userPhoto {
  width: 120px;
  height: 170px;
  position: absolute;
  right: 10px;
  top: 34px;
  border: 1px solid #f5f5f5;
  background: #fdfdfd;
}

.operatingRecordWrap {
  width: 250px;
  position: relative;
  flex-grow: 0;
  padding: 0 10px;

  .operatingRecordTitle {
    background: rgb(240, 240, 240);
    padding: 6px 12px;
    font-weight: bold;
  }

  .operatingRecord {
    min-width: 230px;
    max-width: 230px;
    height: 100%;
    max-height: calc(100vh - 220px);
    padding-left: 16px;
    overflow-y: scroll;
  }
}

.fileListWrap {
  min-width: 230px;
  max-width: 230px;
  height: 100%;
  max-height: calc(100vh - 220px);
  overflow-y: scroll;
}

.fileType {
  font-size: 14px;
  font-weight: bold;
  background: rgb(240, 240, 240);
  padding: 4px 8px;
}

.fileValue {
  border: 1px solid rgb(240, 240, 240);
  padding: 5px;

  & div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 2px 4px;

    &:nth-child(2n) {
      background: rgba(241, 241, 241, 0.8);
    }
  }
}

.fileName {
  color: #1677ff;
  cursor: pointer;

  &:hover {
    color: #409eff;
    text-decoration: underline;
  }
}

.sampleTable {
  width: 820px;
  margin: 0 auto;
  p {
    margin: 0;
  }
}

.blank {
  height: 45px;
  background-color: rgb(251, 251, 251);
}

.fileWrap {
  width: 100%;
  height: calc(100vh - 250px);
  overflow-y: scroll;
  position: relative;
}

