.draggable-modal {
  font-size: 14px;
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-header {
    margin: 0;
  }

  .ant-modal-footer {
    padding: 0 24px 20px;
    margin-top: 0;
  }

  .ant-modal-body {
    padding: 0 15px;
  }

  .ant-modal-close {
    top: 5px;
  }

  .ant-tabs-nav {
    margin: 0;
  }
}
